import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import HelpIcon from '@mui/icons-material/Help';
import FormControlLabel, { FormControlLabelProps } from '@mui/material/FormControlLabel';
import Tooltip from '@mui/material/Tooltip';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import { ApiInspectionDto } from 'kes-common';
import React from 'react';
import { useDispatch } from 'react-redux';

import useActivityScriptToggle from '@/net/reactQuery/mutations/useActivityScriptToggle';
import { inspectionScriptToggle } from '@/store/actions';

import Delete from './Delete';
import Run from './Run';
import Upload from './Upload';

interface ScriptFileProps {
	inspection: ApiInspectionDto;
}

const ScriptFile: React.FC<ScriptFileProps> = ({ inspection }) => {
	const dispatch = useDispatch();

	const activityScript = inspection.scripts.find((s) => s.type === 'ACTIVITY');
	const templateScript = inspection.scripts.find((s) => s.type === 'TEMPLATE');

	const activityScriptToggleMutation = useActivityScriptToggle(inspection.id, {
		onSuccess: (response, variables) => {
			dispatch(inspectionScriptToggle({ toggle: variables.toggle }));
		},
	});

	const onToggleScriptAutomatic: FormControlLabelProps['onChange'] = React.useCallback(
		(event: React.SyntheticEvent, checked: boolean) => {
			activityScriptToggleMutation.mutate({ toggle: checked });
		},
		[activityScriptToggleMutation],
	);

	return (
		<Box marginY={1}>
			<Box alignItems="center" display="flex" justifyContent="center">
				<Typography
					component="p"
					fontWeight="bold"
					marginRight={1}
					textAlign="center"
					variant="overline"
				>
					Script file
				</Typography>
				<Tooltip
					placement="right"
					title="A Python script file that will run against the data from this activity."
				>
					<HelpIcon color="action" fontSize="small" />
				</Tooltip>
			</Box>

			<List dense>
				{activityScript ? (
					<ListItem
						sx={{ paddingRight: 10 }}
						secondaryAction={
							<>
								<Run inspectionId={inspection.id} script={activityScript} />
								<Delete inspection={inspection} script={activityScript} />
							</>
						}
					>
						<ListItemText
							primary={<Typography noWrap>{activityScript.fileName}</Typography>}
							secondary={
								<Tooltip
									title={
										activityScript.toggle
											? 'This script will be automatically executed when rendering the report.'
											: 'This script will not be automatically executed when rendering the report.'
									}
								>
									<FormControlLabel
										control={<Switch checked={activityScript.toggle} size="small" />}
										disabled={activityScriptToggleMutation.status === 'loading'}
										label="Automatic"
										onChange={onToggleScriptAutomatic}
										slotProps={{ typography: { fontSize: 'small' } }}
									/>
								</Tooltip>
							}
						/>
					</ListItem>
				) : (
					<ListItem>
						<Upload inspection={inspection} />
					</ListItem>
				)}

				{templateScript && (
					<ListItem
						sx={{ paddingRight: 10 }}
						secondaryAction={
							<>
								<Run inspectionId={inspection.id} script={templateScript} />
								<IconButton edge="end" aria-label="Delete" disabled>
									<DeleteIcon />
								</IconButton>
							</>
						}
					>
						<ListItemText
							primary={<Typography noWrap>{templateScript.fileName}</Typography>}
							secondary={
								templateScript.toggle ? (
									<Tooltip title="This template script will be automatically executed before rendering the report.">
										<Chip label="Automatic" size="small" />
									</Tooltip>
								) : (
									<Tooltip title="This script is included in the template and can be executed manually at any time.">
										<Chip label="Template script" size="small" />
									</Tooltip>
								)
							}
						/>
					</ListItem>
				)}
			</List>
		</Box>
	);
};

export default ScriptFile;
