import { ApiInspectionDto, ApiScriptToggle } from 'kes-common';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { activityScriptToggle } from '../../api';

const useActivityScriptToggle = (
	inspectionId: ApiInspectionDto['id'],
	options?: UseMutationOptions<Response, unknown, ApiScriptToggle>,
) =>
	useMutation(
		(mutationOptions) =>
			activityScriptToggle(inspectionId, mutationOptions).then((response) =>
				response.expectSuccess(),
			),
		options,
	);
export default useActivityScriptToggle;
