import Alert from '@mui/material/Alert';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import Link from '@mui/material/Link';
import MenuItem from '@mui/material/MenuItem';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Skeleton from '@mui/material/Skeleton';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import { useFormik } from 'formik';
import { ApiCreateStudyDto, ApiServiceDto, ApiStudyDto } from 'kes-common';
import { useSnackbar } from 'notistack';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import * as yup from 'yup';

import { studyCreate, studyUpdate } from '@/net/api';
import {
	useBusinessLines,
	useLabels,
	useMasterTemplates,
	useServices,
	useTemplateLanguages,
} from '@/net/reactQuery/queries';
import { masterTemplates as masterTemplatesURL } from '@/routes';

import TemplateUpload, { TemplateUploadProps } from './TemplateUpload';

const getServiceChipName = (service: ApiServiceDto): string => {
	const marketName = service.marketName ? service.marketName : 'All markets';
	return `${marketName}: ${service.serviceName}`;
};

const getServicesById = (services: ApiServiceDto[]) =>
	services.reduce(
		(entryMap, service) => entryMap.set(service.id, service),
		new Map<string, ApiServiceDto>(),
	);

interface TemplateFormProps {
	formHeaderInfo?: React.ReactNode;
	onSuccess(template: ApiStudyDto): void;
	submitButtonLabel?: string;
	template?: ApiStudyDto;
	templateCreateType: ApiStudyDto['type'];
}

type FormValues = Omit<
	ApiStudyDto,
	| 'draftVersion'
	| 'id'
	| 'lastPublishedDate'
	| 'sharepointUrl'
	| 'useCount'
	| 'users'
	| 'allowScript'
	| 'lastPublishedAssetLibrary'
	| 'file'
> & {
	customTemplateId: string | null;
	masterTemplateId: string | null;
	showNotAnsweredText: boolean;
};

const prepareFormValues = (values: FormValues): ApiCreateStudyDto => ({
	...values,
	allowScript: true,
	masterTemplateId: values.masterTemplateId,
	services: values.services.map((service) => service.id),
	subjects: values.subjects.map((subject) => subject.name),
});

const TemplateForm: React.FC<TemplateFormProps> = ({
	formHeaderInfo,
	onSuccess,
	submitButtonLabel = 'Save',
	template,
	templateCreateType = 'CLASSIC',
}) => {
	const { enqueueSnackbar } = useSnackbar();

	const templateCreate = useMutation(
		(values: FormValues) => studyCreate(prepareFormValues(values)),
		{
			onSuccess: (data) => {
				if (data.status === 200) {
					onSuccess(data.result);
				}
				if (data.status === 400) {
					if (data.result.validationError === false) {
						if (/study with name '.*' already exists/.test(data.result.message)) {
							enqueueSnackbar('The template name already exists');
						}
					}
				}
			},
		},
	);
	const templateUpdate = useMutation(
		(values: FormValues) => studyUpdate(template!.id!, prepareFormValues(values)),
		{
			onSuccess: (data) => {
				onSuccess(template!);
				if (data.status === 400) {
					if (data.result.validationError === false) {
						if (/study with name '.*' already exists/.test(data.result.message)) {
							enqueueSnackbar('The template name already exists');
						}
					}
				}
			},
		},
	);

	const formik = useFormik<FormValues>({
		initialValues: {
			businessLineNames: template?.businessLineNames || [],
			customTemplateId: null,
			description: template?.description || '',
			isPrivate: template?.isPrivate || false,
			labels: template?.labels || [],
			language: template?.language || 'English',
			masterTemplateId: null,
			name: template?.name || '',
			runScriptBeforeReport: template?.runScriptBeforeReport || false,
			services: template?.services || [],
			showNotAnsweredText: template?.showNotAnsweredText ?? true,
			sourceUrl: template?.sourceUrl || '',
			subjects: template?.subjects || [],
			type: templateCreateType,
		},
		onSubmit: (values) => {
			if (template && template.id) {
				templateUpdate.mutate(values, { onSettled: () => formik.setSubmitting(false) });
			} else {
				templateCreate.mutate(values, { onSettled: () => formik.setSubmitting(false) });
			}
		},
		validationSchema: yup.object().shape({
			businessLineNames: yup.array().of(yup.string()).min(1, 'You must select a business line'),
			description: yup.string(),
			isPrivate: yup.boolean().required(),
			labels: yup.array().of(yup.string()),
			language: yup.string().required('You must select a language'),
			name: yup.string().required('You must enter a template name'),
			runScriptBeforeReport: yup.boolean().required(),
			services: yup.array().of(yup.object()).min(1, 'You must select at least one service'),
			showNotAnsweredText: yup.boolean().required(),
			sourceUrl: yup.string().url('You must enter a valid URL'),
			subjects: yup.array().of(yup.string()),
		}),
	});

	const {
		data: businessLines,
		isError: isBusinessLinesError,
		isLoading: isBusinessLinesLoading,
	} = useBusinessLines();
	const {
		data: masterTemplates,
		isError: isMasterTemplatesError,
		isLoading: isMasterTemplatesLoading,
	} = useMasterTemplates();
	const { data: services, isError: isServicesError, isLoading: isServicesLoading } = useServices();
	const { data: labels, isError: isLabelsError, isLoading: isLabelsLoading } = useLabels();
	const {
		data: templateLanguages,
		isError: isTemplateLanguagesError,
		isLoading: isTemplateLanguagesLoading,
	} = useTemplateLanguages();

	const onTemplateUpload = React.useCallback<TemplateUploadProps['onChange']>(
		(customTemplateId) => {
			formik.setFieldValue('customTemplateId', customTemplateId);
		},
		[formik],
	);

	const servicesById = React.useMemo(
		() => (services ? getServicesById(services) : undefined),
		[services],
	);

	if (
		isBusinessLinesError ||
		isLabelsError ||
		isMasterTemplatesError ||
		isServicesError ||
		isTemplateLanguagesError
	) {
		return <Alert severity="error">There was an error loading the form</Alert>;
	}

	if (
		isBusinessLinesLoading ||
		isLabelsLoading ||
		isMasterTemplatesLoading ||
		isServicesLoading ||
		isTemplateLanguagesLoading
	) {
		return (
			<>
				<Skeleton />
				<Skeleton />
				<Skeleton />
			</>
		);
	}

	return (
		<form onSubmit={formik.handleSubmit}>
			{formHeaderInfo && <Box marginBottom={2}>{formHeaderInfo}</Box>}
			<Box marginBottom={2}>
				<TextField
					error={formik.touched.name && Boolean(formik.errors.name)}
					fullWidth
					helperText={
						(formik.touched.name && formik.errors.name) ||
						"The template name should be short and to the point. It's the first thing the user will see in search results and on the project activity card."
					}
					label="Template name"
					margin="normal"
					name="name"
					onBlur={formik.handleBlur}
					onChange={formik.handleChange}
					value={formik.values.name}
					variant="standard"
				/>
			</Box>

			{!template && templateCreateType === 'WORD' && <TemplateUpload onChange={onTemplateUpload} />}

			<Box marginBottom={2}>
				<TextField
					error={formik.touched.description && Boolean(formik.errors.description)}
					helperText={
						(formik.touched.description && formik.errors.description) ||
						'Please provide a short description that explains the purpose of the template so that potential users can find it. We will also use this to post to Yammer in the near future if you want to make it known the template exists.'
					}
					label="Description"
					margin="normal"
					fullWidth
					multiline
					name="description"
					onBlur={formik.handleBlur}
					onChange={formik.handleChange}
					value={formik.values.description}
					variant="standard"
				/>
			</Box>

			{templateCreateType === 'WORD' && (
				<Box marginBottom={2}>
					<TextField
						disabled={Boolean(formik.values.customTemplateId)}
						error={formik.touched.masterTemplateId && Boolean(formik.errors.masterTemplateId)}
						fullWidth
						helperText={
							(formik.touched.masterTemplateId && formik.errors.masterTemplateId) || (
								<span>
									The master template that this template will be based on.&nbsp;
									<Link component={RouterLink} to={masterTemplatesURL}>
										View Master Templates
									</Link>
								</span>
							)
						}
						label="Master template"
						margin="normal"
						name="masterTemplateId"
						onBlur={formik.handleBlur}
						onChange={formik.handleChange}
						select
						value={formik.values.masterTemplateId}
						variant="standard"
					>
						{masterTemplates &&
							masterTemplates.map((masterTemplate) => (
								<MenuItem key={masterTemplate.id} value={masterTemplate.id}>
									{masterTemplate.name}
								</MenuItem>
							))}
					</TextField>
				</Box>
			)}

			<Box marginBottom={2}>
				<TextField
					error={formik.touched.businessLineNames && Boolean(formik.errors.businessLineNames)}
					fullWidth
					helperText={
						(formik.touched.businessLineNames && formik.errors.businessLineNames) ||
						'Please make sure to set the business lines correctly. This helps us to make search results more relevant to the user.'
					}
					label="Business line"
					margin="normal"
					name="businessLineNames"
					onBlur={formik.handleBlur}
					onChange={(event) => formik.setFieldValue('businessLineNames', [event.target.value])}
					select
					value={formik.values.businessLineNames}
					variant="standard"
				>
					{businessLines &&
						businessLines.map((businessLine: string) => (
							<MenuItem key={businessLine} value={businessLine}>
								{businessLine}
							</MenuItem>
						))}
				</TextField>
			</Box>
			{servicesById && services && (
				<Box marginBottom={2}>
					<Autocomplete<ApiServiceDto, true, false, false>
						fullWidth
						getOptionLabel={(service) => service.serviceName}
						groupBy={(service) => (service.marketName ? service.marketName : 'All markets')}
						isOptionEqualToValue={(option, value) => option.id === value.id}
						multiple
						onChange={(_, values) => formik.setFieldValue('services', values)}
						// Make sure services are sorted by marketName, else groupBy will fail to group correctly
						options={services.sort((a, b) => {
							if (a.marketName === null && b.marketName === null) return 0;
							if (a.marketName === null) return -1;
							if (b.marketName === null) return 1;
							if (a.marketName < b.marketName) return -1;
							if (a.marketName > b.marketName) return 1;
							return 0;
						})}
						renderInput={(params) => (
							<TextField
								{...params}
								fullWidth
								variant="standard"
								label="Markets &amp; Services"
								name="services"
								error={formik.touched.services && Boolean(formik.errors.services)}
								helperText={
									(formik.touched.services && (formik.errors.services as string)) || (
										<>
											Please select a Market/Service this template is useful for. This helps people
											to find your template. See{' '}
											<Link
												href="https://global.royalhaskoningdhv.com/markets"
												underline="hover"
												target="_blank"
												rel="noopener"
											>
												Markets
											</Link>{' '}
											and{' '}
											<Link
												href="https://global.royalhaskoningdhv.com/services"
												underline="hover"
												target="_blank"
												rel="noopener"
											>
												Services
											</Link>{' '}
											for more info.
										</>
									)
								}
							/>
						)}
						renderTags={(selectedServices: ApiServiceDto[], getTagProps) =>
							selectedServices.map((service: ApiServiceDto, index: number) => (
								// We render both the marketName as well as serviceName, because services
								// with the same can be in different markets.
								<Chip label={getServiceChipName(service)} {...getTagProps({ index })} />
							))
						}
						value={formik.values.services}
						sx={{ 'margin-top': '16px', 'margin-bottom': '8px' }}
					/>
				</Box>
			)}
			<Box marginBottom={2}>
				<FormControl margin="normal" fullWidth>
					<FormLabel>Language</FormLabel>
					<RadioGroup
						row
						name="language"
						onBlur={formik.handleBlur}
						onChange={(event) => formik.setFieldValue('language', event.target.value)}
						value={formik.values.language}
					>
						{templateLanguages &&
							templateLanguages.map((templateLanguage) => (
								<FormControlLabel
									key={templateLanguage}
									value={templateLanguage}
									label={templateLanguage}
									control={<Radio size="small" />}
								/>
							))}
					</RadioGroup>
					<FormHelperText sx={{ marginLeft: 'unset', marginRight: 'unset' }}>
						Language is used to determine country specific reporting options, for instance the use
						of &quot;and&quot; in lists.
					</FormHelperText>
				</FormControl>
			</Box>
			<Box marginBottom={2}>
				<Autocomplete<string, true, false, true>
					freeSolo
					fullWidth
					multiple
					onChange={(_, values) => formik.setFieldValue('labels', values)}
					options={labels || []}
					renderInput={(params) => (
						<TextField
							{...params}
							fullWidth
							variant="standard"
							label="Labels"
							name="labels"
							error={formik.touched.labels && Boolean(formik.errors.labels)}
							helperText={
								(formik.touched.labels && formik.errors.labels) ||
								'Press enter to save label(s). Please provide a label that relates to your template. You and your users can use this label to quickly find related or the appropriate template. For example if the template relates to an ISO or NEN than a label "ISO" would be a simple label.'
							}
						/>
					)}
					value={formik.values.labels}
					sx={{ marginBottom: 1, marginTop: 2 }}
				/>
			</Box>
			<Box marginBottom={2}>
				<FormControl margin="normal" fullWidth>
					<Box display="flex" justifyContent="space-between">
						<FormLabel>Restrict template usage</FormLabel>
						<Switch
							checked={formik.values.isPrivate}
							color="primary"
							name="isPrivate"
							onChange={formik.handleChange}
							size="small"
						/>
					</Box>
				</FormControl>
				<FormHelperText>
					You can restrict who can add the template to their projects. If you turn this on, only the
					people you have added can use this template. The template will not show up in normal
					searches. In general your template should not be restricted in this manner but during
					development of the template it can be useful.
				</FormHelperText>
			</Box>

			<Box marginBottom={2}>
				<FormControl margin="normal" fullWidth>
					<Box display="flex" justifyContent="space-between">
						<FormLabel>Show &quot;not answered&quot; text</FormLabel>
						<Switch
							checked={formik.values.showNotAnsweredText}
							color="primary"
							name="showNotAnsweredText"
							onChange={formik.handleChange}
							size="small"
						/>
					</Box>
				</FormControl>
				<FormHelperText>
					When checked, templates will show the text &quot;Not answered&quot; when rendering the
					report for any questions left unanswered.
				</FormHelperText>
			</Box>

			{template && (
				<Box marginBottom={2}>
					<FormControl margin="normal" fullWidth>
						<Box display="flex" justifyContent="space-between">
							<FormLabel>Auto run script</FormLabel>
							<Switch
								checked={formik.values.runScriptBeforeReport}
								color="primary"
								name="runScriptBeforeReport"
								onChange={formik.handleChange}
								size="small"
							/>
						</Box>
					</FormControl>
					<FormHelperText>
						When checked, uploaded scripts will automatically be executed before generating reports.
					</FormHelperText>
				</Box>
			)}

			<Box marginTop={2}>
				<Tooltip
					title={
						<>
							<Link
								color="inherit"
								href="https://kes.support.royalhaskoningdhv.com/en/support/solutions/articles/77000501121-creating-templates-"
								rel="noopener noreferrer"
								target="_blank"
							>
								Click here
							</Link>{' '}
							for help on template creation.
						</>
					}
				>
					<span>
						<Button
							color="primary"
							disabled={formik.isSubmitting}
							fullWidth
							type="submit"
							variant="contained"
						>
							{submitButtonLabel}
						</Button>
					</span>
				</Tooltip>
			</Box>
		</form>
	);
};

export default TemplateForm;
